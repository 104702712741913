import { reactive } from 'vue'
import { toLocal } from './storages'

const state = reactive({
	token: null,
	device: null,
	companies: [],
	reload: 0,
})

const actions = {
	setToken(token) {
		state.token = token
	},

	setDevice(device) {
		state.device = device
	},

	setCompanies(companies) {
		state.companies = companies
	},

	setReload(reload) {
		state.reload = reload
	},
}

toLocal(state, {
	// namespace: 'capture',
	attrs: ['token', 'companies', 'device', 'reload'],
})

export { state, actions }
