<template>
	<!-- <div v-if="reload" class="fixed flex inset-0 w-full h-full z-50 bg-gray-800">
		<div class="m-auto max-w-md text-center space-y-6 text-white">
			<p class="text-2xl">
				Hemos dectectado un error en algunas de las imagenes. Por favor recarga la app
			</p>

			<button class="rounded-lg px-6 py-2 bg-teal-500" @click="doReload">Recargar</button>
		</div>
	</div> -->
	<router-view />
	<Notifications />
</template>

<script>
	import { Notifications } from '@/components/notifications'
	import { actions, state } from '@/store'

	export default {
		name: 'App',
		components: {
			Notifications,
		},
		computed: {
			reload() {
				return state.reload
			},
		},
		mounted() {
			const diff = Date.now() - this.reload
			// console.log('diff timestamp: ', diff)
			if (diff >= 5000) {
				// console.log('doing reload')
				actions.setReload(Date.now())
				setTimeout(() => {
					window.location.reload()
				}, 500)
			}
		},
		created() {

			// document.addEventListener(
			// 	'swUpdated', this.showRefreshUI, { once: true }
			// );
			// setTimeout(function() {
			// 	if (navigator.serviceWorker) {  
			// 		console.log('worker:; ', navigator.serviceWorker);
			// 		navigator.serviceWorker.addEventListener(
			// 			'controllerchange', () => {
			// 				window.location.reload();
			// 			}
			// 		);
	
			// 		navigator.serviceWorker.controller.postMessage({
			// 			data: 'weaaa',
			// 			event: 'waldo'
			// 		});
			// 	  }
			// }, 2000);
		},
		methods: {
			// doReload() {
			// 	window.location.reload()
			// },
		},
	}
</script>
