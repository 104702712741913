function getLocal (name) {
    const match = localStorage.getItem(name);

    try {
        return JSON.parse(match);
    } catch (error) {
        removeLocal(name);
        return undefined;
    }
};

function setLocal (name, value) {
    localStorage.setItem(name, JSON.stringify(value));
};

function removeLocal (name) {
    localStorage.removeItem(name);
};

export default (state, options = {}) => {
    const namespace = options.namespace || '';
    const attrs = options.attrs || Object.keys(state);

    for (let attr of attrs) {
        const name = namespace ? `${namespace}-${attr}` : attr;
        const defaultValue = state[attr];

        Object.defineProperty(state, name, {
            get() {
                const value = getLocal(name);
                return value ?? defaultValue;
            },
            set(value) {
                if (value === undefined) {
                    return removeLocal(name);
                }

                return setLocal(name, value);
            }
        });
    }
}