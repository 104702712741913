import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Recognition',
		component: () => import(/* webpackChunkName: "recognition" */ '@/pages/home'),
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
